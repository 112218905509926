@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
  width: 336px;
  height: 136px;
  padding: 12px;
  border-radius: 8px;
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .row {
    height: 56px;
    gap: 16px;
    display: flex;
    align-items: center;

    .btn {
      width: 148px;
      height: 56px;
      padding: 16px;
      gap: 12px;
      border-radius: 12px;
      background: #0000000A;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        @include roboto-regular(14px);
        ;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
      }
    }
  }
}