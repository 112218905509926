@import "../../../../themes/fonts.scss";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;

        .title {
            @include roboto-medium(16px);
            ;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
        }
    }

    .list {
        width: 100%;
        height: 100%;
        padding: 0 24px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .bottom-safe-space {
            width: 100%;
            height: 24px;
        }

        .spinner-wrapper {
            text-align: center;
        }
    }
}