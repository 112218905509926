@import "../../../themes/t_colors.scss";

.container {
    height: 100vh;
    overflow: hidden;
    background: #f1f4f9;

    .head {
        height: 132px;
        background: #f9f9f9;
        box-shadow: 0px 2px 9px 0px #0000000a;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }

    .body {
        height: calc(100% - 132px);
        display: flex;

        .content {
            flex-grow: 1;
            height: 100%;
            padding: 24px 0;
            overflow: auto;
        }

        .right {
            min-width: 52px;
            height: 100%;
            background: #f9f9f9;
        }
    }
}