@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";


.container {
  width: 600px;
  height: auto;
  padding: 52px 120px 52px 120px;
  border-radius: 16px;
  background: $bg-PureWhite-color;
  box-shadow: 0px 2px 9px 0px #0000000a;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .title {
    @include roboto-regular(32px);
    ;
    line-height: 40px;
    text-align: center;
  }

  .link {
    cursor: pointer;
    @include roboto-medium(14px);
    ;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: $primary02-color;
  }

  /*.fpwcheck{
    display:'flex';
    justify-content:'space-between';
}*/

}