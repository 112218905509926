.container {
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;

    .img-check-box {
        margin-right: 10px;
    }

    input {
        width: 18px;
        height: 18px;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
    }

    label {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
    }
}