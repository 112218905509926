.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 16px;
  height: 56px;

  .right {
    cursor: pointer;
    padding-top: 12px;
  }

  .left {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}