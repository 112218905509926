@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    height: 100%;
    margin: 0 24px;
    display: flex;
    background-color: #f9f9f9;
    border-radius: 12px;

    .bill {
        width: 300px;
        overflow: auto;
        border: 18px solid #FFFFFF;
    }

    .content {
        flex-grow: 1;
        height: 100%;
        background-color: #fff;
        padding: 0 0 24px 16px;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            @include roboto-regular(14px);
            ;
            line-height: 20px;
            padding: 16px 0 24px 0;
            letter-spacing: 0.25px;
            align-self: flex-start;
        }

        .form {
            height: 100%;
            border-radius: 4px;
            border: 1px 0px 0px 0px;

            textarea {
                height: 148px;
                width: 624px;
            }
        }

        .btn-group1 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 8px 8px 0;

            h1 {
                @include roboto-medium(16px);
                ;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;
                text-align: left;
            }
        }

        .btn-group2 {
            width: 100%;
            padding-right: 14px;
            display: flex;
            gap: 12px;
            justify-content: flex-end;
        }
    }
}