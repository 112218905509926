@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
  display: flex;
  height: 72px;
  padding: 8px;
  box-shadow: 0px 1px 3px 1px #00000026;
  background-color: $bg-PureWhite-color;

  .product-img {
    align-items: center;
    padding-left: 8px;
    padding-right: 16px;
  }

  .containt {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .text-part {
      margin-top: 6px;

      .heading {
        @include roboto-medium(16px);
        ;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        color: $body-text-color;
      }

      .sub-heading {
        @include roboto-regular(14px);
        ;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $body-text-color;
      }
    }
  }

  .end-part {
    display: flex;
    align-items: center;

    button {
      cursor: pointer;
      background-color: $bg-PureWhite-color;
    }

    .add-icon {
      display: flex;

      .img {
        width: 18px;
        height: 18px;
      }

      p {
        @include roboto-medium(14px);
        ;
        line-height: 20px;
        padding-left: 8px;
        padding-right: 24px;
      }
    }

    .remove-icon {
      padding: 24px 24px 24px 10px;
      height: 72px;
    }
  }
}