.p-progress-bar {
  box-sizing: border-box;
  position: fixed;
  background-color: #6ACCF3;
  width: 100%;
  height: 5px;
  top: 0;
  left: 0;
  overflow: hidden;
}

.p-progress-bar-indeterminate .p-progress-bar-value::before {
  content: '';
  position: absolute;
  background-color: #00A0E0;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: p-progress-bar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: p-progress-bar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.p-progress-bar-indeterminate .p-progress-bar-value::after {
  content: '';
  position: absolute;
  background-color: #00A0E0;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: p-progress-bar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: p-progress-bar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes p-progress-bar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes p-progress-bar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes p-progress-bar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes p-progress-bar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}