@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";


.container {
    width: 600px;
    border-radius: 16px;
    background-color: $bg-PureWhite-color;
    box-shadow: 0px 2px 9px 0px #0000000a;
    padding: 52px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .title {
        color: $body-text-color;
        @include roboto-regular(32px);
        ;
        line-height: 40px;
        text-align: center;
    }
}