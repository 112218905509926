.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(57, 65, 76, 0.25);
    backdrop-filter: blur(2px);
    z-index: 9999;
}