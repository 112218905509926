.spinner-wrapper {
  position: relative;
  display: inline-block;

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 40px;
    height: 40px;

    .path {
      stroke: #00A0E0;
      stroke-linecap: square;
      animation: dash 1.5s ease-in-out infinite;
    }

  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

}