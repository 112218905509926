.container {
    margin: 0 auto;
    background: #ffffff;
    box-sizing: border-box;

    .title {
        font-family: Roboto Mono;
        font-size: 18px;
        font-weight: 700;
        line-height: 23.74px;
        letter-spacing: 1px;
        text-align: center;

        &~.title {
            margin-bottom: 0;
        }
    }

    address {
        font-family: Roboto Mono;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.83px;
        letter-spacing: -0.4967532455921173px;
        text-align: center;
    }

    .tax-invoice-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: Roboto Mono;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.83px;
        letter-spacing: 1.2418831586837769px;
        text-align: center;

        .dash {
            width: 100%;
            border: 1px dashed #979797;
            margin-bottom: 0;
        }

    }

    .head-info-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: end;

        .date {
            display: flex;
            flex-direction: column;
        }

        p {
            font-family: Roboto Mono;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.83px;
            margin-bottom: 6px;

            .bold {
                font-size: 24px;
                font-weight: 400;
                line-height: 31.65px;
                letter-spacing: -0.5px;
            }
        }
    }

    .dash {
        width: 100%;
        border: 1px dashed #979797;
    }

    .item {
        display: flex;
        justify-content: space-between;
        gap: 4px;

        p {
            width: 24px;
            font-family: Roboto Mono;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.83px;
            letter-spacing: 0.4967532455921173px;
            text-align: right;

            &:first-of-type {
                flex-grow: 1;
                text-align: left;
            }

            &:last-of-type {
                width: 32px;
            }
        }
    }

    .sub-total,
    .discount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-family: Roboto Mono;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.83px;
            letter-spacing: 0.4967532455921173px;
            text-align: right;
        }
    }

    .total {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-family: Roboto Mono;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.83px;
            letter-spacing: 0.4967532455921173px;
            text-align: right;

            &+span {
                font-size: 24px;
                font-weight: 400;
                line-height: 31.65px;
                letter-spacing: -0.5px;
            }
        }
    }

    .contact {
        font-family: Roboto Mono;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.83px;
        letter-spacing: 0.4967532455921173px;
    }
}