.container {
    height: 40px;
    padding: 0px 12px;
    display: flex;
    align-items: center;

    .left {
        display: flex;
        align-items: center;

        .active-store {
            width: 216px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .logo {
                width: 24px;
                height: 24px;
                margin-right: 16px;
            }

            .text {
                overflow: hidden;

                .title {
                    color: #333333;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    white-space: nowrap;
                }

                .address {
                    color: #49454F;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}