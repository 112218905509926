@import "../../../themes/fonts.scss";

.container {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  .label {
    cursor: pointer;
    @include roboto-medium(16px);
    ;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }

  .line {
    flex-grow: 1;
    border: 2px solid #1c1c1c33
  }
}