@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.button {
    cursor: pointer;
    padding: 10px 24px;
    border-radius: 100px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
        @include roboto-medium(14px);
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
    }

    &.content {
        width: max-content;
    }

    &.expand {
        width: 100%;
    }

    &.danger {
        color: $bg-PureWhite-color;
        background-color: $accent02;
    }

    &.dark {}

    &.light {}

    &.medium {}

    &.primary {
        color: #fff;
        background-color: $primary02-color;
    }

    &.secondary {}

    &.normal {
        background-color: #F9F9F9;
    }

    &.success {
        color: white;
        background-color: green;
    }

    &.tertiary {}

    &.warning {}

    &.highlight {
        color: #fff;
        background-color: $highlight01;
    }
}