@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    display: flex;
    height: 80px;
    border-radius: 12px;
    background: $body-bg-color;
    padding: 16px;
    box-shadow: 0px 1px 3px 1px #00000026;

    .short-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #3498DB;
        display: flex;
        align-items: center;
        padding: 15px;
        margin-right: 16px;

        p {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            text-align: center;
            color: #FFFFFF;
        }
    }

    .left-contant {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .Employee-Monogram {
            margin-right: 16px;
        }

        .text-part {
            margin-right: 22px;

            .heading {
                // @include roboto-medium-16;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;
                color: $text-black-4;
            }

            .sub-heading {
                // @include roboto-regular-14;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: $text-black-3;
            }
        }
    }

    .right-contant {
        display: flex;
        align-items: center;


        span {
            // @include roboto-regular-14;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #218C5E;
            margin-right: 18px;
        }
    }
}