* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1e6ebfb8;


    &:hover {
      background: #1e6ebf;
    }
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// START: Common Repeated CSS for Elements
button {
  border: none;
}

input,
textarea {
  border: none;

  &:focus-visible {
    outline: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

// END: Common Repeated CSS for Elements