@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    height: 84px;
    padding: 12px 24px 12px 16px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}