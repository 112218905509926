@import "../../../responsive.scss";
@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    width: 100%;
    height: 100%;
    padding: 72px 40px 0 40px;
    background-image: url(../../../assets/images/img_page-on_board_bg.svg);
    background-repeat: no-repeat;
    background-position: bottom right;

    @include md {
        padding-top: 42px;
    }

    .content {
        width: 524px;
        height: auto;
        margin-top: 172px;

        @include md {
            margin-top: 108px;
        }

        h1 {
            margin: 0;
            font-family: 'Roboto';
            font-size: 45px;
            font-weight: 400;
            line-height: 52px;
            text-align: left;
            color: $primary01-color;
            margin-bottom: 24px;
            letter-spacing: 1.2px;
        }

        p {
            letter-spacing: 1px;
            @include roboto-regular(16px);
            ;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left;
            margin-bottom: 24px;
            color: $text-black-5;
        }
    }
}