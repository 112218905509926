.container {
    height: 100%;
    display: flex;

    .avilable-item-wrapper {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .seleted-item-wrapper {
        width: 480px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .row {
            display: flex;
            justify-content: space-between;
            padding-right: 12px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0 24px 12px;
            overflow-y: auto;
        }
    }
}