@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
  width: 280px;
  height: 100%;
  padding: 12px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;

  .head {
    height: 60px;
    padding: 16px;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p {
      @include roboto-medium(16px);
      ;
      line-height: 24px;
    }

    img {
      border-radius: 100px;
    }

    &:hover {
      background: #1C1C1C0D;
    }
  }

  .list {
    flex-grow: 1;
    overflow: auto;

    .item {
      height: 60px;
      padding: 16px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.active {
        background: #F1F4F9;
      }

      img {
        margin-right: 12px;

        &.active {
          display: none
        }
      }

      p {
        @include roboto-regular(16px);
        ;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #49454F;
      }

      &.active {
        img {
          &.active {
            display: block;
          }

          &.default {
            display: none;
          }
        }

        p {
          color: #1A1A1A;
        }
      }

      &:hover {
        transition: 0.8s;
        background: #1C1C1C0D;
      }
    }
  }

  &.sm {
    width: 80px;

    .head {
      border-radius: 50%;

      p {
        display: none;
      }
    }

    .list {
      .item {
        border-radius: 50%;

        p {
          display: none;
        }
      }
    }
  }
}