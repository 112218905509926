@mixin roboto-regular($size) {
    font-family: Roboto;
    font-weight: 400;
    font-size: $size;
}

@mixin roboto-medium($size) {
    font-family: Roboto;
    font-weight: 500;
    font-size: $size;
}