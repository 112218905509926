$bg-PureWhite-color: #ffffff;
$bg-OffWhite-color: #F1F4F9;

$body-bg-color: #f9f9f9;
$body-text-color: #333333;
$body-text-color-0p60: #33333399;
$body-text-color: #000000;

$text-black-1: #1C1C1C;
$text-black-1-op100: #1C1C1Cff;
$text-black-1-op80: #1C1C1Ccc;
$text-black-1-op40: #1C1C1C66;
$text-black-1-op20: #1C1C1C33;
$text-black-1-op5: #1C1C1C0d;

$text-black-2: #322F35;
$text-black-3: #1D1B20;
$text-black-3-op8: #1D1B2014;
$text-black-4: #49454F;
$text-black-5: #39414C;

$primary01-color: #3498db;
$primary02-color: #1E6EBF;
$primary03-color: #4DA7E8;
$primary04-light: #E3F5FF;

$secondary01: #2ECC71;
$secondary02: #27AE60;
$secondary03: #52D681;
$secondary04: #BAEDBD;
$secondary05: #218C5E;

$accent01: #E74C3C;
$accent02: #C0392B;
$accent03: #E95E4E;

$highlight01: #F39C12;
$highlight02: #D35400;
$highlight03: #FFEACA;

//Dark Mode

$dark-bg-color: #1A1A1A;
$dark-font-color: #E6E6E6;

$dark-primary02: #1065BC;

$dark-accent01: #992D22;
$dark-accent02: #B3261E;