@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    display: flex;
    height: 80px;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 1px 3px 1px #00000026;
    background-color: #FFFFFF;
    cursor: pointer;

    .short-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #3498DB;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px;
        margin-right: 16px;
        position: relative;

        p {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            color: #FFFFFF;
        }

        .online-status {
            width: 12px;
            height: 12px;
            background: #2ECC71;
            border: 3px solid #FFFFFF;
            position: absolute;
            left: 28px;
            top: 28px;
            border-radius: 50%;
        }
    }

    .content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .text-part {
            margin-right: 22px;

            .heading {
                @include roboto-medium(16px);
                ;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;
                color: #49454F;
            }

            .sub-heading {
                @include roboto-regular(14px);
                ;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #1D1B20;
            }
        }

        .end-part {
            display: flex;
            align-items: center;

            span {
                @include roboto-regular(14px);
                ;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #218C5E;
                margin-right: 18px;
            }
        }
    }
}