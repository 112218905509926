@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
  position: absolute;
  right: 42px;
  bottom: 42px;
  z-index: 9999;
  height: 42px;
  width: 360px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 12px;
  animation-name: fadeInAnimation;
  animation-duration: 1s;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

  h3 {
    flex-grow: 1;
    text-align: start;
    color: $bg-PureWhite-color;
    padding-right: 48px;
    @include roboto-regular(14px);
    ;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &.default {
    background: $bg-PureWhite-color;

    h3 {
      color: $text-black-2;
    }
  }

  &.success {
    background: $secondary02;
  }

  &.error {
    background: $accent01;
  }

  &.info {
    background: $primary01-color;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}