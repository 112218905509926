@import "../../../responsive.scss";
@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        @include roboto-medium(16px);
        ;
        line-height: 24px;
        text-align: left;
        color: $body-text-color;
    }
}