@import "../../../responsive.scss";
@import "../../../themes/fonts.scss";

.container {
    background-color: #f9f9f9;
    padding-bottom: 24px;
    border-radius: 12px;

    .sub-heading {
        @include roboto-regular(14px);
        ;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        padding: 16px 0 24px 16px;
    }

    .form {
        margin: 0 auto;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px;

        .row {
            width: 648px;
            display: flex;
            gap: 36px;

            .sub-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 24px;
            }
        }

        .row-btn {
            align-self: flex-end;
            display: flex;
            gap: 12px;
        }
    }
}