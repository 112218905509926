@import "../../../responsive.scss";
@import "../../../themes/fonts.scss";

.container {
    background-color: #f9f9f9;
    padding-bottom: 24px;
    height: 100%;
    border-radius: 12px;

    p {
        // @include roboto-regular-14;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        padding-left: 16px;
        padding-bottom: 24px;

        .mandatory {
            color: red;
        }

    }

    .form {
        margin: 0 auto;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px;

        .row {
            width: 648px;
            display: flex;
            gap: 24px;
        }

        .row-btn {
            align-self: flex-end;
            display: flex;
            gap: 12px;
        }
    }
}