@import "../../../responsive.scss";

.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-brand-wrapper {
        width: 1200px;
        position: absolute;
        top: 72px;
        left: auto;
        right: auto;

        @include md {
            top: 42px;
        }
    }
}