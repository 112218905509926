@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";

.container {
    width: 100%;
    position: relative;

    label {
        opacity: 0.5;
        transition: 0.2s;
        color: $body-text-color;
        @include roboto-regular(16px);
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        position: absolute;
        top: 16px;
        left: 16px;

        &.error {
            color: $dark-accent02;
        }
    }

    input {
        width: 100%;
        height: 56px;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid #1A1A1A;
        color: $text-black-3;
        @include roboto-regular(16px);
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        outline: none;

        &:focus.error,
        &.error {
            border: 3px solid #B3261E;
        }

        &:focus {
            border: 3px solid #1E6EBF;
        }

        &:focus,
        &:not(:placeholder-shown) {
            +label {
                opacity: 1;
                transition: 0.5s;
                color: $primary02-color;
                background-color: $bg-PureWhite-color;
                @include roboto-regular(12px);
                line-height: 16px;
                text-align: left;
                padding: 0 4px;
                top: -8px;
                left: 12px;

                &.error {
                    color: $dark-accent02;
                }
            }
        }
    }

    input:-webkit-autofill {
        &+label {
            opacity: 1;
            color: $primary02-color;
            background-color: $bg-PureWhite-color;
            @include roboto-regular(12px);
            line-height: 16px;
            text-align: left;
            padding: 0 4px;
            top: -8px;
            left: 12px;
        }
    }

    .post-icon {
        position: absolute;
        top: 16px;
        right: 12px;
    }

    p.error {
        color: $dark-accent02;
        @include roboto-regular(12px);
        line-height: 16px;
        text-align: left;
        position: absolute;
        left: 16px;
        bottom: -23px;
    }
}