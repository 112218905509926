.right-section {
    width: 312px;
    background: #F9F9F9;
    padding: 12px 24px 0 24px;

    h1 {
        font-family: 'Roboto';
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #49454F;
    }

    p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 4px;
        color: #33333399;

    }

    .row-1 {
        width: 264px;
        padding: 12px;
        border: 0px 0px 1px 0px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E6E6E6;



        .icon-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .icone {
                width: 40px;
                height: 40px;
                gap: 10px;
                border-radius: 100px;
                background: #F1F4F9;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            h2 {
                font-family: 'Roboto';
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #33333399;
                cursor: pointer;
            }
        }

    }

    .datefieldtext {
        width: 224px;
        height: 24px;
        color: #333333;
        margin: 12px 0px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;

    }

    h2 {
        font-family: Roboto;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        margin-bottom: 8px;
    }

    .row-2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0px;
        gap: 12px;

        input {
            width: 126px;
            height: 56px;
            border-radius: 4px 4px 0px 0px;
            cursor: pointer;
        }
    }

    .row-3 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        Button {
            width: 126px;
            height: 40px;
            gap: 8px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;
        }
    }
}