.form-group {
  width: 360px;
  margin-right: 8px;

  .input-group {
    width: 100%;
    padding: 15px 0 15px 24px;
    border-radius: 28px;
    background: #ffffff;
    box-shadow: 0px 1px 3px 1px #0000004d;
    border: 1px solid #d2d5da;
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 16px;
      z-index: 9;
    }

    input {
      color: #333333;
      ;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      width: 85%;
    }
  }
}