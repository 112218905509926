.container {
    width: 360px;
    height: 56px;
    display: flex;
    justify-content: space-between;

    input {
        width: 56px;
        height: 56px;
        border-radius: 12px;
        border: 1px solid #333;
        text-align: center;
        font-size: xx-large;
        outline: none;

        &:focus {
            border: 2px solid blue;
        }
    }
}