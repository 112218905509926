@import "../../../themes/fonts.scss";
@import "../../../themes/t_colors.scss";
@import "../../../responsive.scss";

.container {
    width: 600px;
    border-radius: 16px;
    background-color: $bg-PureWhite-color;
    box-shadow: 0px 2px 9px 0px #0000000a;
    padding: 52px 120px;

    @include md {
        width: 1200px;
        padding: 52px 48px;
    }

    h1 {
        margin-bottom: 32px;
        @include roboto-regular(32px);
        ;
        line-height: 40px;
        color: $body-text-color;
        text-align: center;
    }

    .wrapper-box {
        width: 360px;
        margin: 0 auto;
        padding: 0;

        @include md {
            display: flex;
            align-items: flex-start;
            width: 752px;
            gap: 32px;
        }

        .wrapper-top {
            width: 360px;
            text-align: center;
        }

        .wrapper {

            input {
                margin-bottom: 32px;
                border: 1px solid $dark-bg-color;
                border-radius: 4px;
                background-color: $bg-PureWhite-color;

                @include md {
                    width: 360px;
                }
            }

            &:focus {
                border: 4px solid $primary01-color;
            }
        }

        .resend-text {
            margin: 32px 0 32px 0;
            color: $primary02-color;
            @include roboto-medium(14px);
            ;
            line-height: 20px;
            letter-spacing: 0.1px;
        }

        button[type="submit"] {
            margin: 12px auto 0;
            background-color: transparent;
            color: $text-black-3;
            @include roboto-medium(14px);
            ;
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    }

    .button-box {
        @include roboto-medium(14px);
        ;
        width: 360px;

        @include md {
            display: flex;
            flex-direction: row;
            width: 752px;
            margin: 0 auto;
        }

        button {
            color: $bg-PureWhite-color;
            @include roboto-medium(14px);
            ;
            line-height: 20px;
            letter-spacing: 0.1px;
            height: 40px;
            padding: 10px;

            &:nth-child(1) {
                margin-bottom: 32px;

                @include md {
                    order: 2;
                    margin: 0;
                }
            }

            &:nth-child(2) {

                @include md {
                    order: 1;
                    margin-right: 32px;
                }
            }
        }
    }
}